import React from "react"
import { useStaticQuery, graphql, Script } from "gatsby"


const HTMLHead = () => {
  //Let's bring in the global settings.
  const globalSettings = useStaticQuery(
      graphql`
      query {
          storyblokEntry(slug: {eq: "global-settings"}) {
          content
          name
          }
      }
      `
  )
  const globalSettingsSan = JSON.parse(globalSettings.storyblokEntry.content)
  const CSS = {__html: globalSettingsSan.insert_css};
  return (
    <>
      {globalSettingsSan.gtm_container_id !== '' &&
        <Script strategy="post-hydrate">{"(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','"+globalSettingsSan.gtm_container_id+"');"}</Script>
      }
      
      {globalSettingsSan.insert_css !== '' && 
        <style dangerouslySetInnerHTML={CSS} />
      }
    </>
  )
}
export default HTMLHead