import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { StoryblokComponent } from "gatsby-source-storyblok"; 
import PageTitle from "../../blocks/page_title/page_title"; 
import FlexColumns from "../../blocks/flex_columns/flex_columns"
import Standard from "../../blocks/standard/standard"
import "../resources/_resources.scss" 
import { getCookie } from "../../global/helpers"  
 

const Resources = ({ story }) => { 
 

    const staticQueryData = useStaticQuery(
        graphql`
            query {
                allStoryblokEntry(filter: {field_component: {eq: "form"}}) {
                    nodes {
                        field_component
                        uuid
                        content
                    }
                },
                storyblokEntry(slug: {eq: "global-settings"}) {
                  content
                }
            }
        `
    )

    const getFormID = staticQueryData.allStoryblokEntry.nodes
    ?.map(node => ({
      ...node,
      content: JSON.parse(node.content),
    }))
    .filter(node => node.uuid === story.content.form); 

    //Set title content  
    const pageInfo = {
        name: story.name,
        meta: {
            date_formatted: story.first_published_at,
            resource_type: story.content.resource_type, 
            button_label: story.content.resource_category 
        },
    }
 
    //Set Gated Content
    function GatedContent({ content }) {  
        let flexColumnsContent = { 
            after_content : [],
            background_settings : [
                {
                    color:"electricBlue",
                    component: "background_orb",
                    custom_position_horizontal:"",
                    custom_position_vertical:"",
                    disable_mobile:false,
                    position_horizontal:"center",
                    position_vertical:"center",
                    size:"large",
                    _uid:"gated_content_background_orb"
                }
            ],
            before_content : [],
            column_settings : "",
            column_spacing : "70",
            columns : [ 
                {
                    column_content : [ 
                        {
                            component: "text",
                            max_width: "",
                            text_align: "",
                            text_content:{
                                type: "doc",
                                content: [
                                    {
                                        attrs:{
                                            level: 2
                                        },
                                        type: "heading",
                                        content: [
                                            {
                                                text: content.subheadline,
                                                type: "text"
                                            }
                                        ]
                                    }
                                ]
                            },  
                            // _editable:"",
                            _uid: "gated_text_content_1"
                        },
                        {
                            component: "image",
                            image: content.featured_image,
                            max_height: "",
                            max_width: "",
                            // _editable: "",
                            _uid:"gated_image_1"
                        }, 
                        {
                            component: "text",
                            max_width: "",
                            text_align: "",
                            text_content: content.intro_text,  
                            // _editable:"",
                            _uid: "gated_text_content_2"
                        }   
                    ],
                    column_style :"none",
                    column_width : "50",
                    component:"column_item",
                    // _editable:"",
                    _uid: "gated_column_item_1"
                }, 
                {
                    column_content : [ 
                        {
                            Form: content.form,
                            Style: "primary",
                            button_alignment: "left",
                            component: "form_embed",
                            // _editable: "",
                            _uid: "gated_form_1"
                        }
                    ],
                    column_style :"jet",
                    column_width : "50",
                    component:"column_item",
                    // _editable:"",
                    _uid: "gated_column_item_2"
                }, 
            ],
            component: "flex_columns",
            content_horizontal_align : "",
            content_vertical_align : "flex-start",
            reverse_order_mobile : false,
            row_spacing:"",
            // _editable: story.content._editable,
            _uid: "gated_flex_columns"
        }

        /* add gated blocks to first column */
        for (let i = 0; i < content.gated_content.length; i++) {
            flexColumnsContent.columns[0].column_content.push( content.gated_content[i] );
        }

        return (
            <>  
            <FlexColumns key={content._uid} blok={flexColumnsContent} />
            </>
        )
    } 


    function UnGatedContent({ content }) { 
        
        //Needed to add CTA Banner settings from the global settings. CTA Banner should not be a block local to the story.
        const globalSettings = JSON.parse(staticQueryData.storyblokEntry.content);

        //Set UnGated Content
        let flexColumnsContent = { 
            after_content : [],
            background_settings : [],
            before_content : [],
            column_settings : "",
            column_spacing : "40",
            columns : [ 
                {
                    column_content : [ 
                        // TODO: set secondary title here
                        {
                            component: "image",
                            image: content.featured_image,
                            max_height: "",
                            max_width: "",
                            // _editable: "",
                            _uid:"ungated_image"
                        }   
                    ],
                    column_style :"none",
                    column_width : "65",
                    component:"column_item",
                    // _editable:"",
                    _uid: "ungated_column_item_1"
                }, 
                {
                    column_content : [ 
                        {
                            component: "text",
                            max_width: "",
                            text_align: "",
                            text_content:{
                                type: "doc",
                                content: [
                                    {
                                        attrs:{
                                            level: 2
                                        },
                                        type: "heading",
                                        content: [
                                            {
                                                text: content.subheadline,
                                                type: "text"
                                            }
                                        ]
                                    }
                                ]
                            },  
                            // _editable:"",
                            _uid: "ungated_content_text_1"
                        },  
                        {
                            component: "text",
                            max_width: "",
                            text_align: "",
                            text_content: content.intro_text,  
                            // _editable:"",
                            _uid: "ungated_content_text_2"
                        }   
                    ],
                    column_style :"none",
                    column_width : "35",
                    component:"column_item",
                    // _editable:"",
                    _uid: "ungated_column_item_2"
                }, 
            ],
            component: "flex_columns",
            content_horizontal_align : "",
            content_vertical_align : "center",
            reverse_order_mobile : false,
            row_spacing:"",
            // _editable: story.content._editable,
            _uid: "ungated_flex_columns"
        } 

        let standardContent = {
            _uid: "ungated_standard_content",
            component: "standard",
            after_content: [],
            before_content: [],
            content_blocks: content.ungated_content,
            background_settings: []
        } 

        return (
            <> 
            <div className="ungated_content"> 
                <FlexColumns key={content._uid} blok={flexColumnsContent} />   

                {typeof content.ungated_content != 'undefined' &&
                    <Standard blok={standardContent} />
                } 
            </div> 
            {!!globalSettings.resources_cta_banner?.length &&
                globalSettings.resources_cta_banner.map(blok => (
                    <StoryblokComponent blok={blok} key={blok._uid} />
                ))
            } 
            </>
        ) 
    } 

    //Check to see if the cookie is set by Form ID and if this page is saved in unlocked_pages. 
    const cookieData = getCookie( getFormID[0].content.form_id );//Storing cookie data.  
    let gated = story.content.gated; //if story content is gated, show gated form 

    if ( cookieData && cookieData.unlocked_pages.includes( story.full_slug ) ) {
         //if cookie is set && if story full slug is  in cookie unlocked pages 
         // unlock content
        gated = false;
    }  
    
    return (   
        <>    
            <PageTitle blok={{background_setting:false, collapse_bottom_padding: true}} pageInfo={pageInfo} />  
            {gated && <GatedContent content={story.content} /> }
            {!gated && <UnGatedContent content={story.content} /> } 
        </>  
    )
}

export default Resources
  