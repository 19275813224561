import React from "react"
import { StoryblokComponent } from "gatsby-source-storyblok"
import SinglePost from "../templates/single_post/single_post"
import Resources from '../templates/resources/resources.js' 
  
const HTMLContent = ({ story, pageInfo }) => { 

  const RenderContent = ({field_component}) => {  
    switch( field_component ) { 
      case "resources":          return <Resources story={story} /> ;   
      case "zignal_news":        return <SinglePost story={story} /> ;   
      case "powered_by_zignal":  return <SinglePost story={story} /> ;  
      default:                   return story.content.sections?.map(blok => ( <StoryblokComponent blok={blok} key={blok._uid} pageInfo={pageInfo} /> ))
    } 
  } 

  return <RenderContent field_component={story.field_component} />
}
export default HTMLContent
