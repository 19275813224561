import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { StoryblokComponent } from "gatsby-source-storyblok"
import PageTitle from "../../blocks/page_title/page_title"
import FlexColumns from "../../blocks/flex_columns/flex_columns"

const SinglePost = ({ story }) => {
  // Get global settings
  const globalSettingsQuery = useStaticQuery(
    graphql`
      query {
        storyblokEntry(slug: { eq: "global-settings" }) {
          content
          name
        }
      }
    `
  )
  const globalSettings = JSON.parse(globalSettingsQuery.storyblokEntry.content)

  const pageInfo = {
    name: story.name,
    meta: {
      // date_formatted: story.first_published_at,
      external_link: story.content.external_story_link,
      media_outlet: story.content.media_outlet, // Show media_outlet instead of date per client request
    },
  }

  const introSection = {
    _uid: "intro_section",
    breakpoint: 'desktop',
    columns: [
      {
        _uid: "featured_image_col",
        component: "column_item",
        column_width: 60,
        column_content: [
          {
            _uid: "featured_image",
            component: "image",
            image: story.content.featured_image,
          },
        ],
      },
      {
        _uid: "intro_content_col",
        component: "column_item",
        column_width: 40,
        column_content: [
          {
            _uid: "intro_text",
            component: "text",
            text_content: {
              type: "doc",
              content: [
                ...(story.content.sub_headline
                  ? [
                      {
                        type: "heading",
                        attrs: { level: 2 },
                        content: [
                          { type: "text", text: story.content.sub_headline },
                        ],
                      },
                    ]
                  : []),
                ...story.content.intro_text.content,
              ],
            },
          },
          {
            _uid: "external_story_link",
            component: "button",
            Style: "secondary",
            Label: "Read the Full Story",
            content_target: story.content.external_story_link,
            position_horizontal: "left",
          },
        ],
      },
    ],
  }

  const Divider = () => (
    <div className="inner">
      <hr className="divider" />
    </div>
  )

  return (
    <React.Fragment>
      <PageTitle pageInfo={pageInfo} blok={{ collapse_bottom_padding: true }} />
      <FlexColumns blok={introSection} />
      {!!story.content.content?.length && (
        <React.Fragment>
          <Divider />
          {story.content.content.map(blok => (
            <StoryblokComponent blok={blok} key={blok._uid} />
          ))}
        </React.Fragment>
      )}
      {!!globalSettings.zignal_news_cta_banner?.length &&
        globalSettings.zignal_news_cta_banner.map(blok => (
          <StoryblokComponent blok={blok} key={blok._uid} />
        ))}
    </React.Fragment>
  )
}

export default SinglePost
