import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const HTMLFooter = () => {
    //Let's bring in the global settings.
    const globalSettings = useStaticQuery(
        graphql`
        query {
            storyblokEntry(slug: {eq: "global-settings"}) {
            content
            name
            }
        }
        `
    )
    const globalSettingsSan = JSON.parse(globalSettings.storyblokEntry.content)
    const JS = {__html: globalSettingsSan.insert_javascript}
    return (
        <>
            {globalSettingsSan.gtm_container_id !== '' &&
                <noscript><iframe title="GTM" src={"https://www.googletagmanager.com/ns.html?id=" + globalSettingsSan.gtm_container_id}
                height="0" width="0" style={{display:'none', visibility:'hidden'}}></iframe></noscript>
            }
            <script dangerouslySetInnerHTML={JS} />
        </>
    )
}
export default HTMLFooter