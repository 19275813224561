import React from "react"
import { graphql } from "gatsby"
import { useStoryblokState } from "gatsby-source-storyblok"
import Layout from "../global/layout"
import HTMLHead from '../global/HTMLHead'
import HTMLFooter from '../global/HTMLFooter' 
import HTMLContent from "../global/HTMLContent"
import { getExcerpt } from "../global/helpers"

const StoryblokEntry = ({ data }) => {
  let story = data.storyblokEntry
  story = useStoryblokState(story)

  let pageInfo = {
    name: story.name,
    full_slug: story.full_slug,
    uuid: story.uuid,
    id: story.id,
    internalId: story.internalId,
    slug: story.slug,
    field_component: story.field_component 
  }   

  //Some content types we do not want users to access
  const story_collection = story.full_slug.substring(0,story.full_slug.indexOf("/"))
  const private_collections = data.site.siteMetadata.privateCollections

  if(private_collections.includes(story_collection) !== true){ 
    return (
      <Layout pageInfo={pageInfo}>
        <HTMLContent story={story} pageInfo={pageInfo} /> 
        <HTMLFooter data={data}></HTMLFooter>
      </Layout>  
    )
  }else{
    if (typeof window !== 'undefined') {
      window.location.href = "/404"
    }
  }
}

export const Head = ({ data }) => {
  const metaTitle = data.storyblokEntry.content.metatags?.title || `${data.storyblokEntry.name} | ${data.site.siteMetadata.title}`
  const metaDescription = data.storyblokEntry.content.metatags?.description || getExcerpt(data.storyblokEntry) || data.site.siteMetadata.description
  return(
    <>
      <title>{metaTitle + ' | Zignal Labs'}</title>
      <meta name="description" content={metaDescription} />
      {data.storyblokEntry.content.noIndex ?
        <meta name="robots" content="noindex, nofollow" /> : <meta name="robots" content="index, follow" />
      } 
      <HTMLHead data={data}></HTMLHead>
    </>
  )
}

export default StoryblokEntry
 
export const query = graphql`
  query ($full_slug: String!) {
    storyblokEntry(full_slug: { eq: $full_slug }) {
      content
      name
      full_slug
      uuid
      id
      internalId
      field_component
      slug 
      first_published_at(formatString: "MM•DD•YYYY")
    }
    site {
      siteMetadata {
        title
        description
        privateCollections
      }
    }
  }
`
